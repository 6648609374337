<template>
  <div class="content">
    <h1>Burn Testing - Deprecated</h1>

    <div class="flex flex-col gap-4">
      <b-tabs pills v-model="pageIndex">
        <b-tab title="Tests"></b-tab>
        <b-tab title="Issues"></b-tab>
        <b-tab title="Periods"></b-tab>
      </b-tabs>

      <samples v-if="pageIndex === 0" />
      <issues v-if="pageIndex === 1" />
      <periods v-if="pageIndex === 2" />
    </div>
  </div>
</template>

<script>
const Samples = () => import('@/components/burn_samples/Samples.vue');
const Issues = () => import('@/components/burn_samples/Issues.vue');
const Periods = () => import('@/components/burn_samples/Periods.vue');

export default {
  name: 'BurnSamples',
  components: {
    Samples,
    Issues,
    Periods,
  },
  data() {
    return {
      pageIndex: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
